import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/data/services/dashboard/dashboard.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public pwdForm: FormGroup;
  public o_type : string = "password";
  public n_type : string = "password";
  public c_type : string = "password";
  constructor(
    private router : Router,
    private toastr : ToastrService,
    private dashService : DashboardService,
    private dialog: MatDialog
  ) {
    this.pwdForm = new FormGroup({
      old_password: new FormControl('', Validators.required),
      new_password: new FormControl('', Validators.required),
      c_password: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    
  }

  public viewOldPwd(){
    this.o_type = "text";
  }

  public hideOldPwd(){
    this.o_type = "password";
  }

  public viewNewPwd(){
    this.n_type = "text";
  }

  public hideNewPwd(){
    this.n_type = "password";
  }

  public viewConPwd(){
    this.c_type = "text";
  }

  public hideConPwd(){
    this.c_type = "password";
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.pwdForm.controls[controlName].hasError(errorName);
  }

  public changePwd(formData:any) {
    if (this.pwdForm.invalid) {
      this.pwdForm.markAllAsTouched();
      return;
    }else{
      if(formData.new_password != formData.c_password){
        this.toastr.error("New passsword and confirm password didn't match.");
        return
      }else{
        this.dashService.resetPassword(formData).subscribe({
          next: (response: any) =>{
            if(response.success){
              this.toastr.success(response.message);
              this.dialog.closeAll();
            }else{
              this.toastr.error(response.message);
            }
          },error: (error) =>{
            this.toastr.error(error.message);
          },
          complete :() => {},
        })
      }
    }
  }
}
