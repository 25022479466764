import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token : TokenService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //const origin = location.origin;
    request = request.clone({
      setHeaders: {
        authorization: `${this.token.getToken()}`,
        //'Access-Control-Allow-Origin' : origin
      }
    });
    return next.handle(request);
  }
}
