import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() isExpanded : boolean = false;
  menu_list = [
    {
      title : 'Dashboard',
      route : '/dashboard',
      icon : 'dashboard',
      name : 'Dashboard'
    },
    {
      title : 'Employees',
      route : '/employees',
      icon : 'badge',
      name : 'Employees List'
    },
    {
      title : 'Users',
      route : '/users',
      icon : 'person',
      name : 'Users List'
    },
    {
      title : 'Services',
      route : '/services',
      icon : 'settings',
      name : 'Services List'
    },
    {
      title : 'Offers',
      route : '/offers',
      icon : 'redeem',
      name : 'Offers List'
    },
    {
      title : 'Coupons',
      route : '/coupons',
      icon : 'payments',
      name : 'Coupons List'
    },
    {
      title : 'Upcoming Booking',
      route : '/booking/upcoming-booking',
      icon : 'event_upcoming',
      name : 'Upcoming Bookings'
    },
    {
      title : 'Booking History',
      route : '/booking/booking-history',
      icon : 'work_history',
      name : 'Bookings History'
    },
    {
      title : 'Reports',
      route : '/reports',
      icon : 'report',
      name : 'Reports List'
    },
    {
      title : 'Notifications',
      route : '/notifications',
      icon : 'notifications',
      name : 'Notifications List'
    }
  ]
  constructor(){}
}
