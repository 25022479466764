import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  login(input:any){
    return this.httpClient.post(environment.apiURL + 'adminlogin', input).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

}
