<div class="outer-mat-card">
    <mat-card>
        <mat-card-header><h2>Admin's Details</h2></mat-card-header>
        <mat-divider></mat-divider>
            <form [formGroup]="userProfileForm" (ngSubmit)="updateProfile(userProfileForm.value)">
                <div class="header-container">
                    <div class="left-container">
                        <div class="m-l-15 m-t-10">
                            <mat-form-field class="full-width-input">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="first_name" autocomplete="off">
                                <mat-error *ngIf="hasError('first_name', 'required')">First name is required</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field class="full-width-input">
                                <mat-label>Last Name</mat-label>
                                <input matInput formControlName="last_name" autocomplete="off">
                                <mat-error *ngIf="hasError('last_name', 'required')">Last name is required</mat-error>
                            </mat-form-field>

                            <mat-form-field class="full-width-input">
                                <mat-label>Date of Birth</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="dob" autocomplete="off">
                                <mat-error *ngIf="hasError('dob', 'required')">Date of birth is required</mat-error>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field class="full-width-input">
                                <mat-label>Gender</mat-label>
                                <mat-select formControlName="gender" autocomplete="off">
                                  <mat-option value="male">Male</mat-option>
                                  <mat-option value="female">Female</mat-option>
                                  <mat-option value="other">Other</mat-option>
                                </mat-select>
                                <mat-error *ngIf="hasError('gender', 'required')">Gender is required</mat-error>
                            </mat-form-field>

                            <mat-form-field class="full-width-input">
                                <mat-label>Address</mat-label>
                                <input matInput formControlName="address" autocomplete="off">
                                <mat-error *ngIf="hasError('address', 'required')">Address is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-right-container">
                        <div class="m-l-15 m-t-10">
                            <mat-form-field class="full-width-input">
                                <mat-label>Middle Name</mat-label>
                                <input matInput formControlName="middle_name" autocomplete="off">
                            </mat-form-field>

                            <div class="full-width-input">
                                <mat-form-field class="mid-width-input">
                                    <mat-label>Country Code</mat-label>
                                    <mat-select formControlName="countrycode" autocomplete="off">
                                      <mat-option *ngFor="let code of country_code" value="{{code.phoneCode}}">{{code.countrycode}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('countrycode', 'required')">Code is required</mat-error>
                                </mat-form-field>
                                <mat-form-field class="phone-width-input">
                                    <mat-label>Phone Number</mat-label>
                                    <input matInput formControlName="phone" autocomplete="off">
                                    <mat-error *ngIf="hasError('phone', 'required')">Phone number is required</mat-error>
                                </mat-form-field>
                            </div>

                            <mat-form-field class="full-width-input">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" autocomplete="off">
                                <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
                                <mat-error *ngIf="hasError('email', 'email')">Please enter a valid email address</mat-error>
                            </mat-form-field>

                            <mat-form-field class="full-width-input">
                                <mat-label>File</mat-label>
                                <mat-icon matPrefix (click)="f_input.click()">attach_file</mat-icon>
                                <input type="text" readonly matInput formControlName="avatar" (mousedown)="$event.stopPropagation()">
                                <input type="file" #f_input hidden accept="images" (change)="fileChange(f_input.files)">
                            </mat-form-field>
                            <div class="emp-image" *ngIf="user_data?.avatar">
                                <div class="m-l-15 m-t-10">
                                    <img src="{{user_data.avatar}}" style="height: 100px;width: 100px;">
                                    <button class="add-btn remove-btn" mat-mini-fab (click)="removeImg()">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-button-row">
                    <button class="add-btn" mat-raised-button type="submit">{{btn_txt}}</button>
                </div>
            </form>
    </mat-card>
</div>