import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './shared/profile/profile.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: LoginComponent },
  { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
  {
    path : 'dashboard',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path : 'employees',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/employee/employee.module').then(m => m.EmployeeModule)
  },
  {
    path : 'users',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/users/users.module').then(m => m.UsersModule)
  },
  {
    path : 'services',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/services/services.module').then(m => m.ServicesModule)
  },
  {
    path : 'offers',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/offers/offers.module').then(m => m.OffersModule)
  },
  {
    path : 'coupons',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/coupon/coupon.module').then(m => m.CouponModule)
  },
  {
    path : 'booking',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/booking/booking.module').then(m => m.BookingModule)
  },
  {
    path : 'reports',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path : 'notifications',
    canActivate: [AuthGuard],
    loadChildren : () => import('./modules/notification/notification.module').then(m => m.NotificationModule)
  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
