<mat-sidenav-container autosize style="height:100%">
  <mat-sidenav class="navigation" mode="side" opened="true" [ngClass]="isExpanded ? 'large-screen' : 'small-screen'">
    <mat-nav-list *ngFor="let menu of menu_list">
      <mat-list-item class="list-item" title="{{menu.title}}" [routerLink]="[menu.route]" [routerLinkActive]="['active']">
        <mat-icon class="icon" mat-list-icon>{{menu.icon}}</mat-icon>
        <span class="full-width" *ngIf="isExpanded">{{menu.name}}</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <div class="example-sidenav-content">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>