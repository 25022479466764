<h2 mat-dialog-title>Change Password</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
  <div>
    <form [formGroup]="pwdForm">
        <mat-form-field class="full-width-input">
            <mat-label>Old Password</mat-label>
            <input matInput type="{{o_type}}" formControlName="old_password" autocomplete="off">
            <mat-icon matSuffix *ngIf="o_type == 'password'" (click)="viewOldPwd()">visibility_off</mat-icon>
            <mat-icon matSuffix *ngIf="o_type == 'text'" (click)="hideOldPwd()">visibility</mat-icon>
            <mat-error *ngIf="hasError('old_password', 'required')">Old password is required</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
            <mat-label>New Password</mat-label>
            <input matInput type="{{n_type}}" formControlName="new_password" autocomplete="off">
            <mat-icon matSuffix *ngIf="n_type == 'password'" (click)="viewNewPwd()">visibility_off</mat-icon>
            <mat-icon matSuffix *ngIf="n_type == 'text'" (click)="hideNewPwd()">visibility</mat-icon>
            <mat-error *ngIf="hasError('new_password', 'required')">New password is required</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
            <mat-label>Confirm Password</mat-label>
            <input matInput type="{{c_type}}" formControlName="c_password" autocomplete="off">
            <mat-icon matSuffix *ngIf="c_type == 'password'" (click)="viewConPwd()">visibility_off</mat-icon>
            <mat-icon matSuffix *ngIf="c_type == 'text'" (click)="hideConPwd()">visibility</mat-icon>
            <mat-error *ngIf="hasError('c_password', 'required')">Confirm password is required</mat-error>
        </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Close</button>
  <button mat-raised-button class="add-btn" (click)="changePwd(pwdForm.value)">Save</button>
</mat-dialog-actions>
