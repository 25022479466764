<mat-toolbar color="primary">
  <ng-container *ngIf="isExpanded">
    <img class="large-logo" src="../../../assets/images/logo.png">
  </ng-container>
  <ng-container *ngIf="!isExpanded">
    <img src="../../../assets/images/fav-icon.png">
  </ng-container>
  <div class="main-header">
    <button (click)="expandMenu()" mat-icon-button>
      <mat-icon class="menu">menu</mat-icon>
    </button>
  </div>
  <span class="welcome-text">Hello {{logged_data?.fullname}}</span>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>person_pin</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="adminProfile()"><span>Profile</span></button>
    <button mat-menu-item (click)="changePwd()"><span>Change Password</span></button>
    <button mat-menu-item (click)="logout()"><span>Logout</span></button>
  </mat-menu>
</mat-toolbar>

<app-sidebar [isExpanded]="isExpanded"></app-sidebar>