<div class="signin-content">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
        <img src="assets/images/logo.png" style="height:100px">
        <br><br>
        <mat-form-field class="full-width-input">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" autocomplete="off">
          <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
          <mat-error *ngIf="hasError('email', 'email')">Please enter a valid email address</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" autocomplete="off">
          <mat-error *ngIf="hasError('password', 'required')">Password is required</mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="isLogin">Login</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>