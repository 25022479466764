import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'src/app/shared/change-password/change-password.component';
import { DashboardService } from 'src/app/data/services/dashboard/dashboard.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public isExpanded : boolean = true;
  public logged_data : any;
  constructor(
    private router : Router,
    public dialog: MatDialog,
    private dashService : DashboardService,
  ){
    this.logged_data = JSON.parse(sessionStorage.getItem('auth')!);
  }

  public expandMenu(){
    this.isExpanded = !this.isExpanded;
  }

  public logout(){
    this.dashService.logout().subscribe({
      next: (response: any) =>{
        if(response.success){
          this.router.navigate(['/login']);
        }else{}
      },error: (error) =>{},
      complete :() => {},
    })
    
  }

  public adminProfile(){
    this.router.navigate(['/profile']);
  }

  public changePwd(){
    const dialogRef = this.dialog.open(ChangePasswordComponent,{
      width: '40%',
      autoFocus : false,
      disableClose : false
    });
  }
}
