import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getDashboard(){
    return this.httpClient.get(environment.apiURL + 'dashboard').pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  resetPassword(payload:any){
    return this.httpClient.post(environment.apiURL + 'adminpasswordchange', payload).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  logout(){
    return this.httpClient.get(environment.apiURL + 'logout').pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

}
