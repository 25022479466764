import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  userslist(page_number:number,page_size:number){
    return this.httpClient.get(environment.apiURL + 'userslist?page='+page_number +'&limit='+page_size).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  deleteUser(input:any){
    return this.httpClient.post(environment.apiURL + 'deleteuser', input).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  allUsersList(){
    return this.httpClient.get(environment.apiURL + 'alluserslist').pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  getAdminProfile(){
    return this.httpClient.get(environment.apiURL + 'adminprofile').pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  updateAdminProfile(input:any){
    return this.httpClient.post(environment.apiURL + 'adminprofileupdate', input).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  getUserById(payload:any){
    return this.httpClient.post(environment.apiURL + 'userprofile', payload).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  updateUser(payload:any){
    return this.httpClient.post(environment.apiURL + 'updateUser', payload).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }

  userBlockUnblock(payload:any){
    return this.httpClient.post(environment.apiURL + 'blockunblock', payload).pipe(
      catchError(error =>{
        return throwError(() => error);
      })
    )
  }
}
