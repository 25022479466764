import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public loginForm: FormGroup;
  public isLogin : boolean = false;
  constructor(
    private authService: AuthService,
    private router : Router,
    private toastr: ToastrService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  onSubmit(formData:any) {
    if (this.loginForm.invalid) {
      return;
    }else{
      this.isLogin = true;
      this.authService.login(formData).subscribe({
        next: (response: any) =>{
          if(response.success){
            sessionStorage.setItem('auth',JSON.stringify(response.data));
            this.toastr.success(response.message);
            this.router.navigate(['/dashboard']);
          }else{
            this.isLogin = false;
            this.toastr.error(response.message);
          }
        },error: (error) =>{
          this.isLogin = false;
          this.toastr.error(error.message);
        },
        complete :() => {},
      })
    }
  }
}
