import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { UserService } from 'src/app/data/services/user/user.service';
import { CommonService } from 'src/app/data/services/common/common.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  private userImg : any;
  public userProfileForm: FormGroup;
  public btn_txt : string = "Update Profile";
  public user_data : any;
  public country_code : any = [];
  constructor(
    private toastr: ToastrService,
    private UserService : UserService,
    private commonService : CommonService
  ) {
    this.userProfileForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      middle_name: new FormControl(''),
      last_name: new FormControl('', Validators.required),
      dob : new FormControl('', Validators.required),
      countrycode : new FormControl('', Validators.required),
      phone : new FormControl('', Validators.required),
      gender : new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      address : new FormControl('', Validators.required),
      avatar: new FormControl(''),
    });
  }

  ngOnInit() {
    this.getCountryCode();
  }

  private getCountryCode(){
    this.commonService.getCountryCode().subscribe({
      next: (response: any) =>{
        if(response.success){
          this.country_code = response.data;
          this.getAdminProfile();
        }else{
          this.toastr.error(response.message);
        }
      },error: (error) =>{
        this.toastr.error(error.message);
      },
      complete :() => {},
    })
  }

  private getAdminProfile(){
    this.UserService.getAdminProfile().subscribe({
      next: (response: any) =>{
        if(response.success){
          this.user_data = response['data'];
          this.userProfileForm.patchValue({
            'first_name' : this.user_data.first_name, 'middle_name' : this.user_data.middle_name,
            'last_name' : this.user_data.last_name, 'phone' : this.user_data.phonenumber,
            'email' : this.user_data.email, 'countrycode' : this.user_data.countrycode,
            'gender' : this.user_data.gender, 'dob' : new Date(this.user_data.dob),
            'address' : this.user_data.address
          })
        }else{
          this.toastr.error(response.message);
        }
      },error: (error) =>{
        this.toastr.error(error.message);
      },
      complete :() => {},
    })
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.userProfileForm.controls[controlName].hasError(errorName);
  }

  public fileChange(files : any){
    this.userImg = files[0];
    const maxFileSize = 2097152; // 2 MB
    if(files.length){
      if (this.userImg.type.match('image.*') && this.userImg.size > maxFileSize) {
        this.userProfileForm.patchValue({'avatar' : ''});
        this.toastr.error("The selected image file is too big. Please choose one that is smaller than 2 MB.");
        return
      }else{
        this.userProfileForm.patchValue({'avatar' : files[0].name});
      }
    }else{
      this.userProfileForm.patchValue({'avatar' : ''});
    }
  }

  public updateProfile(formValue:any) {
    if (this.userProfileForm.invalid) {
      return;
    }else{
      var formData: any = new FormData();
      formData.append("first_name", formValue.first_name);
      formData.append("middle_name", formValue.middle_name != null ? formValue.middle_name : "");
      formData.append("last_name", formValue.last_name);
      formData.append("phone", formValue.phone);
      formData.append("countrycode", formValue.countrycode);
      formData.append("email", formValue.email);
      formData.append("gender", formValue.gender);
      formData.append("dob", moment(formData.dob).format('YYYY-MM-DD'));
      formData.append("address", formValue.address);
      formData.append("avatar", this.userImg ? this.userImg : "");
      this.UserService.updateAdminProfile(formData).subscribe({
        next: (response: any) =>{
          if(response.success){
            this.toastr.success(response.message);
          }else{
            this.toastr.error(response.message);
          }
        },error: (error) =>{
          this.toastr.error(error.message);
        },
        complete :() => {},
      })
    }
  }

  public removeImg(){
    this.user_data.avatar = "";
  }
}
